<template>
       <div class="carrier_container">

         <div  class="row ">

           <div v-for="(carrier,index) in carriers " v-bind:key="index"  class="col-md-6 col-lg-6 col-sm-6">
             <div class="job-content bg-white shadow">
              <h4 style="margin-top:20px;"> <router-link class="routeStyle" :to="{name : 'carrier_details' , params:{id:carrier.id}}" > {{ carrier.title }} </router-link> </h4>
              <p> <i class="fa fa-briefcase"></i> vacency : {{ carrier.vacency }} </p>
              <p> <i class="fa fa-map-marker" aria-hidden="true"></i> Mirpur-10,Dhaka </p>
              <p> <i class="fa fa-calendar" aria-hidden="true"></i>  Deadline : {{ carrier.deadline }} </p>
             </div>

          </div>
         </div>
      </div>
</template>
<script>

export default {
        created(){
           this.getCarrierList();
        },
        data(){
          return {
            preview_image : "",
            carriers: "",
          }
        },
        methods:{
          getCarrierList(){
            this.axios.get('carrier/list')
            .then(resp => {
              if (resp.data.success == "OK") {
                this.carriers = resp.data.carriers ;
              }
            })
          },
        }
      }
</script>


<style>

  .carrier_container {

    width: 90%;
    margin: 5% 5%;
 }

  .contact-li{
            list-style-type:none ;
            margin-top:10px;
           }
    .routeStyle:hover{
        color : rgb(241, 150, 13) ;
    }

    .job_container {
      width:100%;
      height: 170px;
      background-color: #ddd;
      border: 2px solid pink;
      border-radius: 10px;
      margin:10px;
    }

    .job-content{
      margin-bottom:5px ;
      padding: 10px 50px;
    }

</style>